<div class="wrapper">
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>
    <div (click)="closePopup()" class="row justify-content-center main-content">
      <div class="col-md-6 col-xs-10" id="pageone" >
          <p class="text_3" style="margin-bottom: 50px;">
            Servizio online di Regione Toscana per il <b>ritiro dei referti</b> relativi alle indagini di laboratorio per la diagnosi di infezione al virus SARS-CoV-2 
            (test sierologici e tamponi molecolari), nell'ambito delle misure per la prevenzione e gestione della emergenza epidemiologica CoViD19.  
          </p>
          <p class="text_3" style="margin-bottom: 50px;">
            <b>ATTENZIONE</b>: i <a href="#col-md-6 col-xs-10" (mouseover)="popup(true)"  (click)="openPopup()" data-rel="popup" data-position-to="window"><b>test rapidi</b></a> per la determinazione qualitativa di antigeni specifici per SARS-CoV-2 (effettuati direttamente nei DRIVE THROUGH), 
            <b>non producono un referto</b>, tuttavia l'esito degli stessi è visualizzabile in tempo reale dal medico curante, per le opportune azioni conseguenti.</p>
          <div class="alert alert-primary text_2" role="alert">
            I tuoi dati <b>sono al sicuro</b>. Per fruire del servizio devi registrarti inserendo i tuoi dati anagrafici, la tua email per il messaggio di conferma ed il tuo numero di cellulare per la ricezione tramite SMS di un codice di sicurezza OTP (One Time Password). 
          </div>
          <div class="button-access text_1">
            <button class="w3-button w3-cyan w3-text-white w3-round " (click)="login()">ACCEDI</button>
          </div>
          <div class="alert alert-danger text_2" role="alert">
            ATTENZIONE : L'utilizzo di questo strumento è monitorato e tracciato per ragioni di sicurezza informatica. Ogni abuso sarà perseguito a norma di legge.​
          </div>
        </div>
      </div>
      <div *ngIf="openpopup" class="popup">
        <a (click)="popup(false)" (mouseout)="popup(false)"><img src="assets/img/tampone.jpeg" alt = "image" style="width:220px;height:220px;" /> </a>
      </div>
      

      <app-footer></app-footer>   
        
</div>