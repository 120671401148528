import { environment } from './../../../environments/environment';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { filter, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  jsonToken: any;
  constructor(private router: Router,  private httpClient: HttpClient, private utility : UtilityService) {
  }

  public getToken(code) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    
    const body = `grant_type=authorization_code&code=${code}&responseType=code&client_id=${environment.client_id}&scope=profile rtroles&redirect_uri=${environment.redirect_uri}`;
    
    return this.httpClient.post(environment.tokenUrl, body, {headers: headers}).pipe(catchError(this.handleError));
  }

  public getAuthCode() {
    let date = new Date().getTime();
    let url = `${environment.authUrl}?response_type=code&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}&scope=profile rtroles?n=${date}`
    window.location.href = url;
  }

  private getUsername (token): void {
    let jsonToken = JSON.parse(this.utility.decodeToken(token));
    let username = jsonToken.given_name + " " + jsonToken.family_name;
    sessionStorage.setItem('username', username )
  }

  public initAuthProcess() {

    let code = (this.getParameterByName('code',window.location.href));

    if (code != null) {
      if (sessionStorage.getItem('access_token') === null) {
        this.getToken(code).subscribe(data => {
          let jsonRes = JSON.parse(JSON.stringify(data));
          this.utility.storeToken(jsonRes)
          this.getUsername(jsonRes.access_token);
           this.router.routeReuseStrategy.shouldReuseRoute = function () {
             return false;
         }
        this.router.navigate(['/listaReferti']);
        });
      } else {
        this.router.navigate(['/listaReferti']);
      }
    } else {
      this.router.navigate(['/home']);
    }

  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
    
  public handleError(error: HttpErrorResponse) {
      let errorMessage = 'Unknown error!';
      if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
      } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      window.alert(errorMessage);
      return throwError(errorMessage);
  }
}
