<div class="wrapper">
    <app-header></app-header>
    <app-breadcrumb></app-breadcrumb>

    <div class="main-content row justify-content-center">
        <div class="col-sm-8">
            <br />
            <br />
            <div *ngIf="refertiCovidList.length > 0">
                <table class="table ">
                    <thead>
                        <tr>
                            <th *ngFor="let header of tableHeaders" scope="col">{{ header }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let referto of refertiCovidList; index as i">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>{{ referto.data }}</td>
                            <td>
                                {{ referto.nomeEsame }}
                            </td>
                            <td>{{ referto.azienda }}</td>
                            <td>
                                <a (click)="getDocumento(referto)" style="cursor:'pointer'">
                                    <img class="icon" src="../../../assets/icon/file-pdf-regular.svg" />
                                </a>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="refertiCovidList.length == 0 && responseEmpty" class="text_3"> 
                <h3 >
                    Attualmente <b>non sono presenti</b> dei referti CoViD19 per te. 
                    Il tuo test potrebbe essere ancora in lavorazione nei nostri laboratori.<br/>
                    Riprova più tardi ! <br />
                </h3>
                <h3 style="margin-top: 50px">
                    Se invece sono trascorse <b>più di 48 ore</b> dall’esecuzione dell’esame, segnalacelo tramite il form online che trovi <a [routerLink]="['/segnalazione']">cliccando qui</a>. <br/>
                    Se hai bisogno di chiarimenti contattaci al numero 055 4385520 (attivo dal lunedì al venerdì dalle 09.00 alle 18.00).
                </h3>
            </div>
            
        </div>
    </div>
    <app-footer></app-footer>

</div>