<div class="wrapper">

    <form name="form" class="form-signin">
      <span class="logo">
        <img src="assets/imgn/logo-regione-toscana.jpg">
      </span>
  
  
  
  
      <label for="profilo">Seleziona Profilo</label>
      <select class="form-control" id="profilo" required formControlName="profilo" >
        <option>
          <h4>PROFILO</h4>
        </option>
      </select>
      <br />

      <button class="btn btn-lg btn-primary btn-block" type="button" (click)="login()" >LOGIN</button>
    </form>
    <br />
  
</div>