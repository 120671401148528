import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RefertoCovid } from '../model/RefertoCovid';
import { DocumentoReferto } from '../model/DocumentoReferto';

@Injectable({
  providedIn: 'root'
})
export class RefertiCovidService {

  private urlGetLista = environment.refertiCovidUrl + '/lista'
  private urlGetDocumento = environment.refertiCovidUrl + '/documento/'
  constructor(private http: HttpClient) { }

  getRefertiCovidList(): Observable<RefertoCovid[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //Authorization: 'my-auth-token',
      })
    };
    return this.http.get<RefertoCovid[]>(this.urlGetLista , httpOptions);
  }

  getDocumento(params): Observable<DocumentoReferto>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token',
      })
    };
    return this.http.get<DocumentoReferto>(this.urlGetDocumento + params , httpOptions);
  }
}
