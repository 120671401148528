import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UtilityService } from '../utility.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService {

  constructor(private router : Router, private utility: UtilityService) { }

  canActivate(next : ActivatedRouteSnapshot, state : RouterStateSnapshot):
  boolean{
    let boolean = true;
    if(sessionStorage.getItem('access_token') == null && sessionStorage.getItem('refresh_token') == null ){
      this.router.navigate(['/home']);
      boolean = false;
    } else if (this.utility.isTokenExpired(sessionStorage.getItem('refresh_token'))) {
      this.router.navigate(['/sessionExpired']);
      boolean = false;
    }
    return boolean;
  }
}
