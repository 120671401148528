<div class="wrapper">
    <app-header></app-header>
    
  
    <div>
                <p class="text_3" style="margin-bottom: 50px;">
                    Segnalazione
                </p>
            
    </div>
    <br/>
    
     <section class="rt-home-content" id="main-content" role="main">
        <div class="container rt-container">
            <div class="row">
                <div class="col-md-6 mx-auto rounded" style="background-color: white; border: 2px solid #e8898ac4 !important;">
                    <div class="rt-wrapper text-center">
                        <div *ngIf="ok!=null&&!ok" class="alert alert-error" style="color: #eb2224; font-weight:bold;">
                        <span class="pficon pficon-error-circle-o">
                            <span class="kc-feedback-text">Si è verificato un errore.</span>
                        </span>
                        </div>
                        <div *ngIf="alert!=null&&alert" class="alert alert-error" style="color: #eb2224; font-weight:bold;">
                            <span class="pficon pficon-error-circle-o">
                                <span  class="kc-feedback-text">Inserire un punto di prelievo</span>
                            </span>
                            </div>
                        <div *ngIf="ok!=null&&ok" class="alert alert-error" style="color: #64dd17; font-weight:bold;">
                            <span class="pficon pficon-error-circle-o">
                                <span  class="kc-feedback-text">Segnalazione inviata correttamente. <br/> <a [routerLink]="['/listaReferti']">Torna alla home</a></span>
                            </span>
                            </div>
                        <div *ngIf="ok==null||!ok" id="kc-form">
                            <div id="kc-form-wrapper">
                                <form valid #form="ngForm" (ngSubmit) = "form">
                                    
                                    <div class="form-group m-3 form-group-lg">
                                        <label class="control-label">Data tampone *</label>

                                        <input
                                            
                                            required
                    
                                            #datainput = "ngModel"
                                            
                                            [ngClass]="
                                            {
                                            'is-invalid': datainput.errors && datainput.touched
                                            }"
                    
                                            placeholder="Data"
                    
                                            name="data"
                                        
                                            [(ngModel)]="segnalazione.data"
                    
                                            type="date" class="form-control" id="data">
                                    </div>
                    
                                    <div class="form-group m-3 form-group-lg">
                                        <label class="control-label">Ora tampone </label>

                                        <input
                                        
                                            placeholder="Ora"
                        
                                            name="ora"
                        
                                            #orainput = "ngModel"
                                            
                                            [ngClass]="
                                            {
                                            'is-invalid': orainput.errors && orainput.touched
                                            }"
                    
                                            [(ngModel)]="segnalazione.ora"
                    
                                            type="time" class="form-control" id="ora">
                                    </div>
                    
                                    <div class="form-group m-3 ">
                                        <label class="control-label">Punto di prelievo *</label>

                                        <select  class="form-control form-control-lg" id="lab"
                                            required
                                        (change)="selectChangeHandler($event)"
                                        >
                                         <option value="" >Seleziona...</option>
                                         <option *ngFor="let lab of labs">{{lab}}</option> 
                                        </select>
                                    </div>
                    
                                    <div class="form-group m-3">
                                        <label for="note">Note</label>
                                        <textarea #note class="form-control" id="note" rows="3" ></textarea>
                                      </div>

                                    <button  [disabled]="!form.valid" (click)="add(note.value); onSubmit(form);" type="submit" class="btn btn-primary m-4">Invia</button>
                                </form>
                                </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </section>


        <br/>
        <br/>

    <app-footer></app-footer>
</div>