import { Injectable } from '@angular/core';

@Injectable()
export class MessaggiService {
  messaggi: string[] = [];
  add(messaggio:string){
    this.messaggi.push(messaggio);
  }
  clear(){
    this.messaggi = [];
  }
  constructor() { }
}
