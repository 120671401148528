<div class="footer">
    <div class="row footer-content">
        <div class="col-sm-4 text_left">
            <img src="assets/images/logoToscana.png">
        </div>
        <div class="col-sm-4 text_left">
            <p>Direzione Diritti di cittadinanza e coesione sociale</p>
            <p>Via Taddeo Alderotti, 26/m</p>
            <p>50139 FIRENZE</p>
        </div>
        <div class="col-sm-4 text_left">
            <p>Help Desk</p>
            <p>Lun-Ven: 09:00 - 18:00, Sab: 09:00 - 13:00</p>
            <p>Numero verde: 800 004477</p>
            <p>Numero nero: 06 77619420</p>
            <p>mail: <a href="mailto:help.saluteonline@regione.toscana.it">help.saluteonline@regione.toscana.it</a> (per informazioni e problemi sui dati)</p>
            <p>Link alla <a href="https://www.regione.toscana.it/contactcenter/" class="link-footer">chat di supporto</a></p>
            </div>
        <div class="footer-sub-footer">
            <p>Regione Toscana © 2020 | 
                <a href="http://fascicolosanitario.regione.toscana.it/" target="_blank">Fascicolo Sanitario</a>
                | 
                <a href="https://www.regione.toscana.it/privacy" target="_blank">Privacy</a> 
                | 
                <a href="https://www.regione.toscana.it/-/accessibilita-e-uso-del-sito" target="_blank">Accessibilità</a></p>
        </div>
    </div>
    
</div>