

<div class="wrapper">

   
    <div class="logo-mini">
      <img src="assets/imgn/logo-regione-toscana.jpg">
    </div>
    
    <h1>SESSIONE SCADUTA <button class="bbio btn btn-primary button" (click)="redirectHome()"> Torna alla home </button> </h1> 

   
  
  
</div>
