import { Component, OnInit } from '@angular/core';
import { RefertiCovidService } from '../../services/referti-covid.service';
import { RefertoCovid } from '../../model/RefertoCovid';
import * as moment from 'moment'; 
import { NgxSpinnerService } from "ngx-spinner";
import { catchError } from 'rxjs/operators';


declare var require: any;

@Component({
  selector: 'app-lista-referti',
  templateUrl: './lista-referti.component.html',
  styleUrls: ['./lista-referti.component.css']
})
export class ListaRefertiComponent implements OnInit {

   tableHeaders = [' ', 'Data Accettazione', 'Esame', 'Azienda', 'Documento' ]
   refertiCovidList = [];
   responseEmpty: boolean;

  constructor(private refertiService: RefertiCovidService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.refertiService.getRefertiCovidList().subscribe(
      res => {
        if(res.length != 0){
          for( var referto of res){
            referto.data = moment(referto.data).format("DD/MM/YYYY");
          }
          this.refertiCovidList = res;
        } else {
          this.responseEmpty = true;
        }

      this.spinner.hide();
    },
    err => {
      this.responseEmpty = true;
      this.spinner.hide();
    })
  }

  getDocumento(referto: RefertoCovid): void{
    let params = "";
    params += (referto.azienda + "/");
    params += (referto.idReferto);
    
    this.refertiService.getDocumento(params).subscribe( res => {
      const pdfJson = res;
      var FileSaver = require('file-saver');
      if(pdfJson.file != null ){
        fetch("data:" + pdfJson.mimeType + ";base64," + pdfJson.file)
        .then(function (resp) { return resp.blob() })
        .then(function (blob) {
          const nameFile = referto.nomeEsame + '-' + referto.data + '.' + pdfJson.estensione;
          FileSaver.saveAs(blob, nameFile)
        });
      }
    })
  }

}
