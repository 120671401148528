
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//cambiare le url per avere l'autenticazione di questa applicazione
export const environment = {
  production: false,
  refertiCovidUrl: 'https://apistage.regione.toscana.it/sanita/C01/referti-covid/v1',
  authUrl: 'https://accessosicuro.rete.toscana.it/idp/realms/rt3/protocol/openid-connect/auth',
  tokenUrl: 'https://accessosicuro.rete.toscana.it/idp/realms/rt3/protocol/openid-connect/token',
  logoutUrl: 'https://accessosicuro.rete.toscana.it/idp/realms/rt3/protocol/openid-connect/logout',
  issuer: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa',
  redirect_uri: 'https://referticovidtest.sanita.toscana.it',
  client_id: 'referti-covid-client-test',

};

