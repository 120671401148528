import {Injectable} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MessaggiService } from './messaggi.service';

@Injectable()
export class UtilityService {

  errorMessageSave = 'Errore nel Salvataggio dei dati. Si prega di riprovare ';
  errorMessageFind = 'Errore nella ricerca. Si prega di riprovare';
  successMessageSave = 'Dati Salvati con successo';

  constructor(
    private messageService: MessaggiService
  ) {}


  getAlertSave() {
    alert(this.errorMessageSave);
  }
  getAlertSaveSuccess(){
    alert(this.successMessageSave);
  }

  getAlertFind() {
    alert(this.errorMessageFind);
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      this.log(`Si è verificato un problema. Riprovare`);
      console.error('Si è verificato un errore:', error.error.message);
      throw new Error('Si è verificato un errore');
    } else {
      if (error.status === 404) {
        console.error('errore 404: non trovato');
        return '{}';
      } else {
        this.log(`Si è verificato un problema. Riprovare`);
        console.error(
          `Dal BackEnd arriva un errore ${error.status}, ` +
          `body was: ${error.error}`);
        throw new Error('Si è verificato un errore');
      }
    }
  }

  private log(messaggio: string) {
    this.messageService.add(messaggio);
  }

  decodeToken(token): string {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return jsonPayload;
  }

  isTokenExpired(token) : Boolean {
    var stringa = JSON.parse(this.decodeToken(token));
    var expiryDate = new Date( stringa.exp*1000);
    var currentDate = new Date();

    return currentDate > expiryDate;
  }

  storeToken(jsonRes : any) {
    sessionStorage.setItem('access_token', jsonRes.access_token);
    sessionStorage.setItem("refresh_token", jsonRes.refresh_token);
    sessionStorage.setItem("token_type", jsonRes.token_type);
  }

  deleteToken() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("token_type");
  }

}
