import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.css']
})
export class SessionExpiredComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  redirectHome(): void {

    sessionStorage.clear();
    location.reload();
  }

}
