import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthorizationService } from '../../services/autenticazione/authorization.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  openpopup: Boolean;
  click: Boolean;

  public constructor(private titleService: Title,private authService: AuthorizationService, private router: Router    ) { }

  public setTitle() {
    this.titleService.setTitle( "Regione Toscana | HOME Ritiro referti COVID19" );
  }

  ngOnInit(): void {
    this.click=false;
    this.openpopup=false;
    this.setTitle();
    this.redirectSession();

  }

  login(): void {
    this.authService.getAuthCode();
    //this.router.navigate(['/listaReferti']);
  }

  redirectSession():void {
    if(sessionStorage.getItem('username')){
    this.router.navigate(['/listaReferti']);
  }
  }

  openPopup(): void{
    this.openpopup = true;
    this.click = true;
  }

  closePopup(): void{
    if(this.click === true){
      this.click = false;
    } else{
      this.openpopup = false;
    }
  }

  popup(boolean) {
    this.openpopup = boolean;
  }

}
