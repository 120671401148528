import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Segnalazione } from '../model/Segnalazione';
import { SegnalazioneHd } from '../model/SegnalazioneHd';

@Injectable({
  providedIn: 'root'
})
export class SegnalazioneService {
  postId;
  private urlGetSegnalazione = environment.refertiCovidUrl + '/segnalazione'
  private urlGetSegnalazioneHD = environment.refertiCovidUrl + '/segnalazione-hd'
  ok:boolean = true;
  constructor(private http: HttpClient) { }

  getLabs(): any {
      return ['ABBADIA SAN SALVADORE - PARCO MINERARIO',
      'AREZZO -Rampa di fronte alla QUESTURA',
      'Arezzo - Stadio Curva Ospiti',
      'Aulla - Distretto',
      'Avenza',
      'Badia a Ripoli (RAMI)',
      'Bibbiena - Distretto',
      'Campi Bisenzio - Firenze',
      'Campi Bisenzio (Rami)',
      'CARRARA - DISTRETTO AVENZA',
      'Castel del Piano - Struttura Kronos',
      'Castelfranco di sopra',
      'Cecina - Ospedale',
      'Certaldo (Rami)',
      'Chianciano Terme - c/o ex Ospedale',
      'Cortona - Ospedale Santa Margherita',
      'Empoli (AUSL) - Via Cappuccini',
      'Empoli (Rami)',
      'Empoli - Centro Direzionale',
      'Firenze Misericordia di Firenze',
      'Firenze San Salvi',
      'Firenze Sata Rosa',
      'Follonica - Piazzale Distretto di Follonica',
      'Fornaci di Barga',
      'Fratellanza Popolare e Croce d\'Oro Grassina',
      'Grosseto - Piazzale Pizzetti',
      'Livorno - Porto',
      'Livorno - vicino allo stadio',
      'Lucca - Presidio Campo di Marte',
      'Massa - Distretto Le Villette',
      'Massa - Ex Ospedale Massa',
      'Montecatini (Rami)',
      'Mugello - Fondazione Estote Misericordes',
      'Orbetello - Ospedale San Giovanni di Dio',
      'Piombino - Porto',
      'Piombino - Punto Prelievi Ospedale',
      'Pisa Distretto',
      'Pistoia (AUSL) - Via Matteotti',
      'Pistoia (Rami)',
      'Poggibonsi - Ospedale Campostaggia',
      'POMARANCE CASA DELLA SALUTE',
      'Pontedera - Dip Prevenzione',
      'Portoferraio - Ospedale',
      'Prato - Parchieggio Vecchio Ospedale',
      'Prato - Presidio Prato Centro Est "CSS Giovannini',
      'Prato (Rami)',
      'PT Valdinievole (AUSL) - Villa Ankuri Via I Maggio, Massa e Cozzile',
      'San Mauro an Signa (Rami)',
      'San Sepolcro - sede Foro Boario',
      'Siena - Parcheggio S. Francesco piano 1S',
      'Stazione FS Arezzo',
      'Stazione FS Firenze SMN',
      'Stazione FS Grosseto',
      'Stazione FS Pisa',
      'Stazione FS Viareggio',
      'Villa Pergher - Versilia',
      'Volterra - Distretto',
      'Altro'];
  }

  postSegnalazioneHd(segnalazione: SegnalazioneHd): any {
  
    var body;
    if(segnalazione.ora==null){
      body = {
        codiceFiscale: segnalazione.cf,
        nome: segnalazione.nome,
        cognome: segnalazione.cognome,
        numeroDiTelefono: segnalazione.cellulare,
        dataTampone: segnalazione.data + ' 00:00',
        laboratorio: segnalazione.lab,
        email: segnalazione.email,
        note: segnalazione.note
      };
    }else{
       body = {
        codiceFiscale: segnalazione.cf,
        nome: segnalazione.nome,
        cognome: segnalazione.cognome,
        numeroDiTelefono: segnalazione.cellulare,
        dataTampone: segnalazione.data + ' ' + segnalazione.ora,
        laboratorio: segnalazione.lab,
        email: segnalazione.email,
        note: segnalazione.note
      };
    }

    const headers = {'Content-Type': 'application/json' };
    return this.http.post<any>(this.urlGetSegnalazioneHD, body ,{headers});
    
  }

  postSegnalazione(segnalazione: Segnalazione): any {
    var body;
    if(segnalazione.ora==null){
      body = {
        dataTampone: segnalazione.data + ' 00:00',
        laboratorio: segnalazione.lab,
        note: segnalazione.note
      };
    }else{
       body = {
        dataTampone: segnalazione.data + ' ' + segnalazione.ora,
        laboratorio: segnalazione.lab,
        note: segnalazione.note
      };
    }

    const headers = {'Content-Type': 'application/json' };
  
    
    return this.http.post<any>(this.urlGetSegnalazione, body ,{headers});
}

}
