import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationService } from './services/autenticazione/authorization.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'referti-covid-fe';

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private authService: AuthorizationService
    ){}

    ngOnInit(){
      this.authService.initAuthProcess();
    }
}
