import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeComponent } from '../component/home/home.component';
import { ListaRefertiComponent } from '../component/lista-referti/lista-referti.component';
import { SessionExpiredComponent } from '../component/error/session-expired/session-expired.component';
import { GuardService } from '../services/autenticazione/guard.service';
import { SegnalazioneComponent } from '../segnalazione/segnalazione.component';
import { SegnalazioneHdComponent } from '../segnalazione-hd/segnalazione-hd.component';


@NgModule({
  imports: [
    RouterModule.forRoot([ 
      { path: 'home', component: HomeComponent },
      { path: 'listaReferti', component: ListaRefertiComponent, canActivate: [GuardService] },
      { path: 'sessionExpired', component: SessionExpiredComponent },
      { path: 'segnalazione', component: SegnalazioneComponent, canActivate: [GuardService]},
      { path: 'segnalazioneHelpDesk', component: SegnalazioneHdComponent, canActivate: [GuardService]},
      { path: '**', redirectTo: '/home', pathMatch: 'full' }
    ]),      
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
