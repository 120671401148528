import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  home: boolean;

  constructor() { }

  ngOnInit(): void {
    this.home = this.isHome();
  }


  isHome(): boolean {
    let position = false;
    if(window.location.href.includes('home')){
      position = true;
    } 
    return position;
  }

}
