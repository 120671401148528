import { Component, Input, OnInit } from '@angular/core';
import { SegnalazioneHd } from '../model/SegnalazioneHd';
import { SegnalazioneService } from '../services/segnalazione.service';


@Component({
  selector: 'app-segnalazione-hd',
  templateUrl: './segnalazione-hd.component.html',
  styleUrls: ['./segnalazione-hd.component.css']
})
export class SegnalazioneHdComponent implements OnInit {
 
  @Input() segnalazione: SegnalazioneHd;
  ok: Boolean;
  alert: Boolean;
  auth: Boolean
  labs = [];
  constructor(private segnalazioneService: SegnalazioneService) {
   }

  ngOnInit() {
    this.segnalazione = {} as SegnalazioneHd;
    this.labs = this.segnalazioneService.getLabs();

  }
  
  onSubmit({value, valid}: {value: SegnalazioneHd, valid: boolean}) {
    this.ok=null;
    this.alert=null;
    value.lab= this.segnalazione.lab;
    value.note=this.segnalazione.note;

    if (valid && value.lab!=null && value.lab.length>0) {
      
      this.segnalazioneService.postSegnalazioneHd(value).subscribe(
        res => {this.ok=true},
        err => {if(err=='Forbidden'){
          this.auth=false;
        }else{
         this.ok=false;
          }  })
   
    } else {
      console.log('Form not valid');
      this.alert=true;    
    }
  }

  add(note: string){
    this.segnalazione.note=note;
  }
  selectChangeHandler (event: any) {
    this.alert=null;
    this.segnalazione.lab = event.target.value;
  }

}
