import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  username: string;
  home: boolean
  segnalazioneHelpDesk: boolean
  menu_mobile: any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.menu_mobile = document.getElementById("menu_mobile");
    this.username = sessionStorage.getItem('username');
    this.home = this.isHome();
    this.segnalazioneHelpDesk = this.isSegnalazioneHelpDesk();
  }

  openMenuMobile(open: boolean): void {
    if(open){
      this.menu_mobile.classList.add("menu_mobile_open");
      this.menu_mobile.classList.remove("menu_mobile_closed");
    } else {
      this.menu_mobile.classList.remove("menu_mobile_open");
      this.menu_mobile.classList.add("menu_mobile_closed");
    }
  }

  isHome(): boolean {
    let position = false;
    if(window.location.href.includes('home')){
      position = true;
    } 
    return position;
  }

  isSegnalazioneHelpDesk(): boolean {
    let position = false;
    if(window.location.href.includes('segnalazioneHelpDesk')){
      position = true;
    } 
    return position;
  }

  logout(): void {
    sessionStorage.clear();
    window.location.href = `${environment.logoutUrl}?redirect_uri=${environment.redirect_uri}`;

  }

}
