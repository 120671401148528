<div class="padding0 col-xs-12 ">
    <div class="padding0 main-header col-xs-12 col-sm-12 col-md-12 ">
            <div class="padding0 col-xs-12 col-sm-12 col-md-6">
                <img src="assets/images/logoToscana.png">
                <img src="assets/images/logoToscana_2.png">
                <i *ngIf="home || segnalazioneHelpDesk" class="hidden-md hidden-lg fa icon-black fa-bars fa-2x" (click)="openMenuMobile(true)"></i>
                <div *ngIf="!home && !segnalazioneHelpDesk" class=" hidden-md hidden-lg user-menu-mobile">
                    <div class="user">
                    <div class="dropdown ">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                            <ng-container>
                                <i class="fa icon-black fa-user"></i>
                            </ng-container>     
                        </a>
                        <div class="dropdown-menu openLogout">
                            <li class="user-footer">
                                <span class="user-text">
                                    {{ username }}
                                </span>
                                <a (click)="logout()" class="btn btn-default btn-flat">
                                    <i class="fa  fa-sign-out"></i>logout</a>
                            </li>
                        </div>
                    </div>
                    </div>
                </div>
            </div>

        <div *ngIf="home||segnalazioneHelpDesk" class=" col-md-6  ">
            <i class="hidden-xs hidden-sm hidden-lg fa icon-black fa-bars fa-2x" (click)="openMenuMobile(true)"></i>
            <a href="http://fascicolosanitario.regione.toscana.it/" target="_blank" class=" hidden-xs hidden-sm hidden-md btn btn-info rounded">
                Fascicolo
                Sanitario </a>
            <a href="https://prenota.sanita.toscana.it/" target="_blank" class="hidden-xs hidden-sm hidden-md btn btn-danger rounded"> CUP Online </a>
            <a href="https://celiachia.sanita.toscana.it/" target="_blank" class="hidden-xs hidden-sm hidden-md btn btn-success rounded"> Celiachia</a>
        </div>
        <div *ngIf="!home && !segnalazioneHelpDesk" class="hidden-xs hidden-sm col-md-4 user-menu">
            <div class="user">
            <div class="dropdown ">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                    <ng-container>
                        <span class="user-text hidden-xs">
                            {{ username }}
                        </span>
                        <i class="fa icon-black fa-user"></i>
                    </ng-container>     
                </a>
                <div class="dropdown-menu openLogout">
                    <li class="user-footer">
                        <a (click)="logout()" class="btn btn-default btn-flat">
                            <i class="fa  fa-sign-out"></i>logout</a>
                    </li>
                </div>
            </div>
            </div>
        </div>
    </div>
    

    <div id="menu_mobile" class="menu_mobile_closed">
        <div class="content_menu_mobile">
            <p class="text_menu_mobile"> <span class="fa fa-angle-left fa-2x" style="cursor: pointer;"
                    (click)="openMenuMobile(false)"></span> &nbsp; &nbsp; Altri servizi </p>
            <div style="text-align: center; margin-top: 50px">
                <div style="margin-top: 5px">
                    <a href="http://fascicolosanitario.regione.toscana.it/" target="_blank"
                        class=" btn btn-info rounded"> Fascicolo Sanitario </a>
                </div>
                <div class="" style="margin-top: 5px">
                    <a href="https://prenota.sanita.toscana.it/" target="_blank" class=" btn btn-danger rounded"> CUP
                        Online </a>
                </div>
                <div class="" style="margin-top: 5px">
                    <a href="https://celiachia.sanita.toscana.it/" target="_blank" class=" btn btn-success rounded">
                        Celiachia </a>
                </div>
            </div>
        </div>
    </div>
</div>