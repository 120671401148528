import { Component, Input, OnInit } from '@angular/core';
import { Segnalazione } from '../model/Segnalazione';
import { SegnalazioneService } from '../services/segnalazione.service';


@Component({
  selector: 'app-segnalazione',
  templateUrl: './segnalazione.component.html',
  styleUrls: ['./segnalazione.component.css']
})
export class SegnalazioneComponent implements OnInit {
  @Input() segnalazione: Segnalazione;
  ok: Boolean;
  alert: Boolean;
  labs = [];
  constructor(private segnalazioneService: SegnalazioneService) {
  }

  ngOnInit() {
    this.segnalazione = {} as Segnalazione;
    this.labs = this.segnalazioneService.getLabs();
  }
  
  onSubmit({value, valid}: {value: Segnalazione, valid: boolean}) {
    this.ok=null;
    this.alert=null;
    value.note=this.segnalazione.note;
    value.lab= this.segnalazione.lab;
    if (valid && value.lab!=null && value.lab.length>0) {
     this.segnalazioneService.postSegnalazione(value).subscribe(
      res => {this.ok=true},
    err => {
      this.ok=false;
    })
    
    } else {
      console.log('Form not valid');
      this.alert=true;
    }
  }


  add(note: string){
    this.segnalazione.note=note;
  }
  selectChangeHandler (event: any) {
    this.segnalazione.lab = event.target.value;
    this.alert=null;
  }

}
