import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { HeaderComponent } from './component/header/header.component';
import { HomeComponent } from './component/home/home.component';
import { RefertiCovidService } from './services/referti-covid.service'
import { ListaRefertiComponent } from './component/lista-referti/lista-referti.component';
import { AuthorizationService } from './services/autenticazione/authorization.service';
import { Interceptor } from './services/autenticazione/interceptor';
import { UtilityService } from './services/utility.service';
import { MessaggiService } from './services/messaggi.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SessionExpiredComponent } from './component/error/session-expired/session-expired.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { FooterComponent } from './component/footer/footer.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { SegnalazioneComponent } from './segnalazione/segnalazione.component';
import { FormsModule } from '@angular/forms';
import { SegnalazioneHdComponent } from './segnalazione-hd/segnalazione-hd.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    HomeComponent,
    ListaRefertiComponent,
    SessionExpiredComponent,
    FooterComponent,
    BreadcrumbComponent,
    SegnalazioneComponent,
    SegnalazioneHdComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule
  ],
  providers: [
    RefertiCovidService,
    AuthorizationService,
    UtilityService,
    MessaggiService,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
