<div class="container-fluid">
    <div class="row main-content justify-content-center">
        <div class="row col-md-8 col-xs-12">
            <div *ngIf="home" class="col-4">
                <button id="button1" class="btn active_button rounded">
                    <span class="number_button">1</span>
                </button>
                <span class="text_button">Registrati</span>
            </div>
            <div *ngIf="!home" class="col-4">
                <button id="button1" class="btn rounded">
                    <span class="number_button">1</span>
                </button>
                <span class="text_button">Registrati</span>
            </div>
            <div class="col-4">
                <button id="button2" class="btn  rounded">
                    <span class="number_button">2</span>
                </button>
                <span class="text_button">Accedi</span>
            </div>
            <div *ngIf="home" class="col-4">
                <button id="button3" class="btn rounded">
                    <span class="number_button">3</span>
                </button>
                <span class="text_button">Scarica referto</span>
            </div>
            <div *ngIf="!home" class="col-4">
                <button id="button3" class="btn active_button rounded">
                    <span class="number_button">3</span>
                </button>
                <span class="text_button">Scarica referto</span>
            </div>
        </div>
    </div>
</div>